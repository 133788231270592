/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, Menu, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Výtvarná súťaž"}>
        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"1k2xo2czmsf"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"jqn5icwqqt"}>
        </SeparateLine>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(245,166,35,1)"}} name={"08dzrkjmu0el"}>
        </SeparateLine>


        <Column className="--menu css-g5bxvk --parallax" style={{"paddingTop":0,"paddingBottom":0}} menu={true} name={"repkopet198"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=3000x_.jpg);
    }
  
    `}>
          
          <Menu className="--full" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box js-anim  --anim3 --anim-s8" anim={"3"} animS={"8"}>
              
              <Image style={{"maxWidth":100}} alt={"Logo"} src={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png"} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox style={{"marginTop":0,"paddingTop":0}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--001" href={"/"} target={""} content={"Hlavná stránka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/kniznica"} target={""} content={"Knižnica"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/projekty"} target={""} content={"Projekty"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/podujatia"} target={""} content={"Podujatia"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/#vystavy"} target={""} content={"Výstavy"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/muzeum-online#vystavy"} target={""} content={"Múzeum online"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"7r15xu02ci"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255,255,255,1)"}} name={"xkhphd26gt"}>
        </SeparateLine>


        <Column className="css-g5bxvk --parallax pb--60 pt--60" name={"1dqnwc17fiy"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--shape3 js-anim  --anim6 --anim-s5" style={{"maxWidth":827,"backgroundColor":"rgba(27,46,53,0.6)","paddingBottom":119}} anim={"6"} animS={"5"}>
              
              <Title className="title-box title-box--center ff--1 fs--72 swpf--uppercase" content={"<span style=\"color: var(--white);\">Výtvarná súťaž&nbsp;</span><span style=\"color: var(--black);\">„ O najkrajšiu veľkonočnú pohľadnicu „</span><span style=\"color: var(--white);\">  už pozná svojich víťazov.</span><br>"}>
              </Title>

              <Text className="text-box ff--1 fs--36" content={"<span style=\"color: var(--white);\">V prvej kategórií – materské školy boli ocenení</span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--1 fs--36" content={"Etelka G.  6 rokov<br>Tomáš P. 6.rokov<br>"}>
              </Text>

              <Text className="text-box ff--1 fs--36" content={"<span style=\"color: var(--white);\">V druhej kategórií  - 1. stupeň základných škôl</span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--1 fs--36" content={"Vivien B. 9 rokov<br>Ella L.    8.rokov<br>"}>
              </Text>

              <Text className="text-box ff--1 fs--36" content={"<span style=\"color: var(--white);\">V tretej kategórií –  špeciálne základné školy</span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--1 fs--36" content={"Nicolas R.  10 rokov<br>Romanka Č. 17.rokov<br>"}>
              </Text>

              <Text className="text-box ff--1 fs--36" style={{"paddingTop":132}} content={"<span style=\"color: var(--white);\">Ďakujeme za účasť a tešíme sa na ďalšie výtvarné práce v iných súťažiach. Výhercov budeme telefonicky kontaktovať.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g5bxvk --parallax" style={{"paddingTop":272,"paddingBottom":0}} name={"vtbxk4zm98"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/5cf444bf5b6f4c32b8eebd599b128c6e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2 --full" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--left" style={{"maxWidth":168}} src={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png"} sizes="(max-width: 639px) 100vw, 50vw" srcSet={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":8.53125,"backgroundColor":"rgba(0,0,0,1)"}} name={"19r1mtqhy1m"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left ff--3 fs--14" style={{"marginTop":2.3125,"paddingTop":0}} content={"<span style=\"color: rgb(155, 155, 155);\">© 2023 Tribečské múzeum v Topoľčanoch, všetky práva vyhradené</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}